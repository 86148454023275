<template>
  <div class="Container">
    <el-container>
        <el-header class="Header">
            <el-row justify="space-between">
                <el-col :span="2">
                    <div class="Index" @click="ToIndex()">首页</div>
                </el-col>
                <el-col :span="2">
                </el-col>
            </el-row>
        </el-header>
    </el-container>
    <!-- <div class="HeaderName">
        摸鱼
    </div> -->
    <div class="Banner">
        <div class="ItemTag">
            <div class="TagName"><i class="iconfont icon-biaoqian"></i></div>
            <div class="ItemTagName">摸鱼游戏</div>
        </div>
        <div class="ItemDetailsList">
            <div class="Item" title="一个可以在线玩红白机游戏的网站" @click="RedWhite">
                <div class="ItemLogo">
                    <img src="../../../public/RedW.jpg">
                </div>
                <div class="ItemDetailsInfo">
                    <div class="InfoTop"><strong>红白之家</strong></div>
                    <div class="InfoBottom">一个可以在线玩红白机游戏的网站</div>
                </div>
            </div>
            <div class="Item" title="既然人生都重开了，各种技能..." @click="Reborn">
                <div class="ItemLogo">
                    <img src="../../../public/chongkai.jpg">
                </div>
                <div class="ItemDetailsInfo">
                    <div class="InfoTop"><strong>人生重开模拟器</strong></div>
                    <div class="InfoBottom">既然人生都重开了，各种技能...</div>
                </div>
            </div>
            
            <div class="Item" title="一个升级版在线连连看小游戏..." @click="LineToLine">
                <div class="ItemLogo">
                    <img src="../../../public/LineToLine.png">
                </div>
                <div class="ItemDetailsInfo">
                    <div class="InfoTop"><strong>在线连连看</strong></div>
                    <div class="InfoBottom">一个升级版在线连连看小游戏...</div>
                </div>
            </div>
            
        </div>
        <div class="ItemTag">
            <div class="TagName"><i class="iconfont icon-biaoqian"></i></div>
            <div class="ItemTagName">摸鱼资讯</div>
        </div>
        <div class="ItemDetailsList">
            <div class="Item" title="舔狗日记" @click="TianGou">
                <div class="ItemLogo">
                    <img src="../../../public/Dog.png">
                </div>
                <div class="ItemDetailsInfo">
                    <div class="InfoTop"><strong style="color:#f1404b">舔狗日记</strong></div>
                    <div class="InfoBottom">舔狗之家</div>
                </div>
            </div>
            <div class="Item" title="收集整理各类沙雕新闻，合适..." @click="ShadiaoNew">
                <div class="ItemLogo">
                    <img src="../../../public/ShadiaoNew.png">
                </div>
                <div class="ItemDetailsInfo">
                    <div class="InfoTop"><strong>沙雕新闻</strong></div>
                    <div class="InfoBottom">收集整理各类沙雕新闻，合适...</div>
                </div>
            </div>
            <div class="Item" title="网络梗大搜罗" @click="OnlineGeng">
                <div class="ItemLogo">
                    <img src="../../../public/xiaoji.png">
                </div>
                <div class="ItemDetailsInfo">
                    <div class="InfoTop"><strong>小鸡词典</strong></div>
                    <div class="InfoBottom">网络梗大搜罗</div>
                </div>
            </div>
            <div class="Item" title="好用的电影推荐类网站，脑图..." @click="MovieChart">
                <div class="ItemLogo">
                    <img src="../../../public/Movie.png">
                </div>
                <div class="ItemDetailsInfo">
                    <div class="InfoTop"><strong>Mvcat</strong></div>
                    <div class="InfoBottom">好用的电影推荐类网站，脑图...</div>
                </div>
            </div>
            
        </div>
        <div class="GameFrameBox">
            <div class="InnerGameBox">
                <div class="GameBoxContainer">
                    <iframe :src="url" frameborder="0" class="Enterainment"></iframe>
                </div>
                <div class="GameFooter">
                    <el-button type="primary" size="middle" @click="OffGameBox">关闭</el-button>
                </div>
            </div>
        </div> 
    </div>
    
  </div>
</template>

<script>
export default {
    name:'Entertainment',
    data(){
        return{
            url:'',
        }
    },
    mounted(){
        this.ClickToHideParent()
        this.ClickToHideChirld()
    },
    methods:{
        ToIndex(){
            this.$router.push({path:'/'})
        },
        /*
        *第一个标签方法
        */
        RedWhite(){
            this.url = 'https://nes.heheda.top/'
            $('.GameFrameBox').css('display','flex')
        },
        Reborn(){
            this.url = 'https://liferestart.syaro.io/public/index.html'
            $('.GameFrameBox').css('display','flex')
        },  
        DrawerLine(){
            this.url = 'https://www.xiwnn.com/huaxian/'
            $('.GameFrameBox').css('display','flex')
        },
        LineToLine(){
            this.url = 'https://lines.frvr.com/'
            $('.GameFrameBox').css('display','flex')
        },

        /*
        *第二个标签方法
        */
        TianGou(){
            this.url = 'https://we.dog/'
            $('.GameFrameBox').css('display','flex')
        },
        ShadiaoNew(){
            this.url = 'https://shadiao.plus/'
            $('.GameFrameBox').css('display','flex')
        },
        OnlineGeng(){
            this.url = 'https://jikipedia.com/'
            $('.GameFrameBox').css('display','flex')
        },
        MovieChart(){
            this.url = 'https://www.mvcat.com/'
            $('.GameFrameBox').css('display','flex')
        },
        /*
        *其余部分
        */
        OffGameBox(){
            $('.GameFrameBox').hide()
            this.url = ''
        },
        ClickToHideParent(){
            $('.GameFrameBox').click(function(){
                $(this).hide()
            })
            this.url = ''
        },
        ClickToHideChirld(){
            $('.InnerGameBox').click(function(event){
                event.stopPropagation()
            })
        }
    }
}
</script>

<style scoped>
    
.Container{
    width: 100%;
    height: 100%;
}
.Header{
    line-height: 60px;
    width: 100%;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 0 !important;
    z-index: 20;
}
.Index , .Square , .QA ,.Active{
  height: 100%;
  font-size: 16px;
  cursor: pointer;
}
.Index:hover ,.Active:hover, .Square:hover , .QA:hover {
  background: #dddddd;
}
.HeaderName{
    height: 40px;
    width: 100%;
    padding: 5px;
    font-size: 24px;
    font-weight: bold;
    box-sizing: border-box;
    color: black;
    background: white;
}
.Banner{
    position: absolute;
    top: 60px;
    bottom: 0;
    width: 100%;
    background: #f9f9f9;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 15px 0px 15px;
}
.ItemTag{
    position: relative;
    height: 30px;
    width: 100%;
    display: flex;
}
.TagName{
    left: 0;
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
}
.TagName > .icon-biaoqian{
    position: absolute;
    left: 0;
    font-size: 24px;
}
.ItemTagName{
    font-size: 24px;
}
.ItemDetailsList{
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 20px 0;
    /* background: gray; */
    display: flex;
    flex-wrap: wrap;
}
.Item{
    height: 60px;
    /* width: 305px; */
    background: rgb(255, 255, 255);
    transform: translate(0px,0px);
    border-radius: 4px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0px 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}
.Item:hover{
    transform: translateY(-6px);
    box-shadow: 0px 0px 20px -5px rgb(158 158 158 / 40%);
    transition: transform 0.2s linear;
}
.Item:hover .InfoTop{
    color: #f1404b;
}
.ItemLogo{
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.ItemLogo img{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.ItemDetailsInfo{
    margin-left: 10px;
    /* flex: 1; */
    width: 220px;

}
.InfoTop{
    text-align: left;
    padding: 2px 0;
    font-size: 16px;
}
.InfoBottom{
    font-size: 12px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #6c757d!important;
}
.GameFrameBox{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    background: #6c757d81;
    align-items: center;
    justify-content: center;
}
.InnerGameBox{
    width: 90%;
    height: 90%;
    background: white;
}
.GameBoxContainer{
    width: 100%;
    height: 90%;
}
.Enterainment{
    width: 100%;
    height: 100%;
}
.GameFooter{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
    width: 100%;
}
</style>