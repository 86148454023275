var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Container"},[_c('el-container',[_c('el-header',{staticClass:"Header"},[_c('el-row',{attrs:{"justify":"space-between"}},[_c('el-col',{attrs:{"span":2}},[_c('div',{staticClass:"Index",on:{"click":function($event){return _vm.ToIndex()}}},[_vm._v("首页")])]),_c('el-col',{attrs:{"span":2}})],1)],1)],1),_c('div',{staticClass:"Banner"},[_vm._m(0),_c('div',{staticClass:"ItemDetailsList"},[_c('div',{staticClass:"Item",attrs:{"title":"一个可以在线玩红白机游戏的网站"},on:{"click":_vm.RedWhite}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"Item",attrs:{"title":"既然人生都重开了，各种技能..."},on:{"click":_vm.Reborn}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"Item",attrs:{"title":"一个升级版在线连连看小游戏..."},on:{"click":_vm.LineToLine}},[_vm._m(5),_vm._m(6)])]),_vm._m(7),_c('div',{staticClass:"ItemDetailsList"},[_c('div',{staticClass:"Item",attrs:{"title":"舔狗日记"},on:{"click":_vm.TianGou}},[_vm._m(8),_vm._m(9)]),_c('div',{staticClass:"Item",attrs:{"title":"收集整理各类沙雕新闻，合适..."},on:{"click":_vm.ShadiaoNew}},[_vm._m(10),_vm._m(11)]),_c('div',{staticClass:"Item",attrs:{"title":"网络梗大搜罗"},on:{"click":_vm.OnlineGeng}},[_vm._m(12),_vm._m(13)]),_c('div',{staticClass:"Item",attrs:{"title":"好用的电影推荐类网站，脑图..."},on:{"click":_vm.MovieChart}},[_vm._m(14),_vm._m(15)])]),_c('div',{staticClass:"GameFrameBox"},[_c('div',{staticClass:"InnerGameBox"},[_c('div',{staticClass:"GameBoxContainer"},[_c('iframe',{staticClass:"Enterainment",attrs:{"src":_vm.url,"frameborder":"0"}})]),_c('div',{staticClass:"GameFooter"},[_c('el-button',{attrs:{"type":"primary","size":"middle"},on:{"click":_vm.OffGameBox}},[_vm._v("关闭")])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemTag"},[_c('div',{staticClass:"TagName"},[_c('i',{staticClass:"iconfont icon-biaoqian"})]),_c('div',{staticClass:"ItemTagName"},[_vm._v("摸鱼游戏")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemLogo"},[_c('img',{attrs:{"src":require("../../../public/RedW.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemDetailsInfo"},[_c('div',{staticClass:"InfoTop"},[_c('strong',[_vm._v("红白之家")])]),_c('div',{staticClass:"InfoBottom"},[_vm._v("一个可以在线玩红白机游戏的网站")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemLogo"},[_c('img',{attrs:{"src":require("../../../public/chongkai.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemDetailsInfo"},[_c('div',{staticClass:"InfoTop"},[_c('strong',[_vm._v("人生重开模拟器")])]),_c('div',{staticClass:"InfoBottom"},[_vm._v("既然人生都重开了，各种技能...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemLogo"},[_c('img',{attrs:{"src":require("../../../public/LineToLine.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemDetailsInfo"},[_c('div',{staticClass:"InfoTop"},[_c('strong',[_vm._v("在线连连看")])]),_c('div',{staticClass:"InfoBottom"},[_vm._v("一个升级版在线连连看小游戏...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemTag"},[_c('div',{staticClass:"TagName"},[_c('i',{staticClass:"iconfont icon-biaoqian"})]),_c('div',{staticClass:"ItemTagName"},[_vm._v("摸鱼资讯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemLogo"},[_c('img',{attrs:{"src":require("../../../public/Dog.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemDetailsInfo"},[_c('div',{staticClass:"InfoTop"},[_c('strong',{staticStyle:{"color":"#f1404b"}},[_vm._v("舔狗日记")])]),_c('div',{staticClass:"InfoBottom"},[_vm._v("舔狗之家")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemLogo"},[_c('img',{attrs:{"src":require("../../../public/ShadiaoNew.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemDetailsInfo"},[_c('div',{staticClass:"InfoTop"},[_c('strong',[_vm._v("沙雕新闻")])]),_c('div',{staticClass:"InfoBottom"},[_vm._v("收集整理各类沙雕新闻，合适...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemLogo"},[_c('img',{attrs:{"src":require("../../../public/xiaoji.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemDetailsInfo"},[_c('div',{staticClass:"InfoTop"},[_c('strong',[_vm._v("小鸡词典")])]),_c('div',{staticClass:"InfoBottom"},[_vm._v("网络梗大搜罗")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemLogo"},[_c('img',{attrs:{"src":require("../../../public/Movie.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ItemDetailsInfo"},[_c('div',{staticClass:"InfoTop"},[_c('strong',[_vm._v("Mvcat")])]),_c('div',{staticClass:"InfoBottom"},[_vm._v("好用的电影推荐类网站，脑图...")])])
}]

export { render, staticRenderFns }